<template>
  <div class="groups box">
    <div class="title b-b fx-row fx-center">
      <!-- 当前拼团状态 -->
      <div class="fx-1">Status Grup</div>
    </div>

    <div class="content">
      <!-- 拼团中 -->
      <template v-if="status == 0">
        <div class="times-txt">Waktu tersisa untuk membentuk grup</div>

        <div class="times-box">
          <times type="max" key="1" :remain="remain" @over="onOver"></times>
        </div>

        <div class="members fx-row fx-center">
          <template v-for="(item, index) of members">
            <div class="avatar"
                 :class="{master: item && item.is_initiator || false,
                 empty:!item && (status == 0),
                 free: !item && (status == 1)}"
                 :key="index">
              <div v-if="item" :style="{backgroundImage: item.avatar}"></div>
            </div>
          </template>
        </div>

        <!-- 拼团中-->
        <div class="desc" v-if="status == 0">Butuh <span>{{lessNum}} orang</span> lagi dan anda akan mendapatkan kupon
          diskon
        </div>
      </template>

      <!-- 拼团成功 -->
      <template v-else-if="status > 0">
        <div class="result"
             :class="{success: (status == 1 && hasJoin == 1),
             code: couponCode && status == 1 && hasJoin == 1}">
          <div class="sub-title" v-if="status == 1 && hasJoin == 1">Selamat! Grup berhasil terbentuk!</div>
          <div class="sub-title" v-else-if="status == 1 && hasJoin != 1">Maaf! Kamu terlambat</div>
          <!-- 失败 -->
          <div class="sub-title" v-else-if="status > 1">Penyesalan! Grup gagal</div>

          <div class="desc" v-if="!couponCode && status == 1 && hasJoin == 1">
            Harap perhatikan untuk memeriksa informasi saat mengisi ulang tagihan telepon
          </div>
          <div class="desc" v-else-if="status == 1 && hasJoin != 1">
            Temanmu zen berhasil bergabung dengan grup
          </div>
          <!-- 失败 -->
          <div v-else-if="status > 1">
            Grup gagal terbentuk, Silahkan coba lagi
          </div>

          <!-- 拼团成功，存在优惠券码 -->
          <!-- 免费券不展示couponCode -->
          <div class="coupon-code" v-if="couponCode && status == 1 && hasJoin == 1">
            <div class="sub-title">— KODE KUPON —</div>
            <div class="val" id="copyDiv" @click="onCopyCode">
              {{couponCode}}
              <div class="copySuccessMessage" :style="{transform: isCopySuccessMessageShow ? 'translate(-50%, -140px)' : 'translate(-50%, -130px)', opacity: isCopySuccessMessageShow ? '1' : '0'}">
                {{copyStatus}}
              </div>
            </div>
            <div class="txt">Klik kode diskon di atas untuk memasukkan toko untuk digunakan,Tidak ada diskon tanpa kupon.</div>
            <!-- <div class="txt">Salin kode dan top up</div> -->
            <!-- 复制优惠码 -->
            <!-- Salin kode dan top up -->
            <!-- 优惠码已自动复制 -->
            <!-- Kode diskon telah secara otomatis disalin -->
            <!-- 点此复制优惠码 -->
            <!-- Klik Salin kode tawaran -->
          </div>

          <div class="info fx-row fx-center">
            <div class="txt">Waktu Yang Digunakan</div>
            <div class="fx-1">
              <times type="min over" key="2" only-show="1" :during="during"></times>
            </div>
            <div class="members fx-row fx-nowrap">
              <template v-for="(item, index) of members">
                <div class="avatar"
                     :class="{master: item && item.is_initiator || false,
                     empty:!item && (status == 0),
                     free: !item && (status == 1)}"
                     :key="index">
                  <div v-if="item" :style="{backgroundImage: item.avatar}"></div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import Clipboard from '@/utils/clipboard'
  import formatBackgroundImgUrl from '../../../filters/formatBackgroundImgUrl'
  import formatDealineDate from '../../../filters/formatDealineDate'
  //component
  import Times from '../../../components/times'

  export default {
    props: ['info'],
    components: {
      Times
    },
    data() {
      return {
        isCopySuccessMessageShow: false,
        copyStatus: 'Salin berhasil',
      }
    },
    computed: {
      status() {
        return this.info && this.info.status || 0
      },
      hasJoin() {
        return this.info && this.info.has_join || 0
      },
      couponCode() {
        return this.info && this.info.coupon_code || ''
      },
      lessNum() {
        return (this.info && this.info.expected_members || 0) - (this.info && this.info.current_members || 0)
      },
      members() {
        let members = [].concat(this.info.members || this.info.Members || [])
        let total = this.info && this.info.expected_members || 0

        for (let i = 0; i < total; i++) {
          let item = members[i]

          if (!item) {
            members.push(false)
          } else {
            item.avatar = formatBackgroundImgUrl(item.avatar || '')
          }
        }

        members.sort((a, b) => {
          return b.is_initiator - a.is_initiator
        })

        return members
      },
      remain() {
        return formatDealineDate(this.info.start_time, this.info.group_duration) - (this.info.current_time || 0)
      },
      during() {
        return this.info.complete_time - this.info.start_time
      }
    },
    methods: {
      onOver() {
        this.$emit('over')
      },
      onCopyCode() {
        if (this.couponCode && Clipboard && Clipboard.isSupported()) {
          let clipboard = new Clipboard('#copyDiv', {
            text: () => this.couponCode
          })

          clipboard.on("success", () => {
            this.isCopySuccessMessageShow = true;
            this.copyStatus = 'Salin berhasil';
            setTimeout(()=>{
              this.isCopySuccessMessageShow = false;
            }, 1500)
            clipboard.destroy();    // 避免第一次点击后多次回调
          })
        } else {
          // 复制不成功的情况
          this.isCopySuccessMessageShow = true;
          this.copyStatus = 'Salin gagal';
          setTimeout(()=>{
            this.isCopySuccessMessageShow = false;
          }, 1500)
        }
      },
    },
    created(){
      setTimeout(()=>{})
    }
  }
</script>

<style lang="scss" scoped>
  .groups {
    .content {
      min-height: pxTo(165);
      padding-top: pxTo(16);
      padding-bottom: pxTo(40);

      .times-txt {
        text-align: center;

        @extend %no-break;
        @extend %ff-rr;
        @include fs-l-w-c(12, 14, 400, $c10);
      }

      .times-box {
        width: pxTo(156);
        height: pxTo(25);
        margin: 0 auto;
        margin-top: pxTo(8);
      }

      .members {
        margin-top: pxTo(28);

        &.fail {
          opacity: .5;
        }

        .avatar {
          width: pxTo(48);
          height: pxTo(48);
          padding: pxTo(2);
          background: $bg1;
          position: relative;
          margin-left: pxTo(20);
          overflow: visible;

          &.master {
            margin-left: 0;
            margin-right: pxTo(20);

            &::after {
              content: "";
              display: block;
              width: pxTo(20);
              height: pxTo(20);
              position: absolute;
              bottom: 0;
              right: pxTo(-5);

              @include bg($img-base, 'ic_avatar_host.svg');
              background-size: 100% 100%;
            }

            &::before {
              content: "";
              display: block;
              width: 1px;
              height: pxTo(21);
              background: $c14;
              right: pxTo(-20);

              @extend %v-center;
            }
          }

          &.empty {
            @include bg($img-base, 'ui_avatar_add.svg');
            background-size: 100% 100%;

            > div {
              display: none;
            }
          }

          &.free {
            @include bg($img-base, 'ui_avatar_coupon.svg');
            background-size: 100% 100%;

            > div {
              display: none;
            }
          }
        }
      }

      .desc {
        padding: 0 pxTo(20) 0 pxTo(20);
        margin-top: pxTo(11);
        text-align: center;

        @extend %ff-rr;
        @include fs-l-w-c(12, 14, 400, $c10);

        > span {
          @extend %ff-rb;
          @include fs-l-w-c(12, 14, 900, $c16);
        }
      }

      .result {
        padding: 0 pxTo(20);
        padding-top: pxTo(91+12);
        text-align: center;

        @include bg($img-base, 'fail.png');
        background-size: pxTo(91) pxTo(91) !important;
        background-position: top center !important;


        &.success {
          @include bg($img-base, 'success.png');
        }

        &.code {
          background: none !important;
          padding-top: 0 !important;
        }

        .sub-title {
          text-align: center;

          @extend %ff-rm;
          @include fs-l-w-c(16, 19, 500, $c9);
        }

        .desc {
          margin-top: pxTo(3);
        }

        .info {
          margin-top: pxTo(20);

          .txt {
            margin-right: pxTo(6);

            @extend %no-break;
            @include fs-l-w-c(11, 13, 400, $c12);
          }

          .members {
            margin: 0;

            .avatar {
              width: pxTo(24);
              height: pxTo(24);
              padding: pxTo(1);

              &::after {
                width: pxTo(12);
                height: pxTo(12);
              }

              &::before {
                content: "";
                width: 1px;
                height: pxTo(10);
                right: pxTo(-11);

                @extend %v-center;
              }

              margin: 0;
              margin-left: pxTo(-5);

              &.master {
                margin: 0;
                margin-right: pxTo(18+5);
              }
            }
          }
        }
      }

      .coupon-code {
        padding-bottom: pxTo(10);

        .sub-title {
          margin-top: pxTo(42);
          height: pxTo(16);
          text-align: center;

          @extend %ff-rb;
          @include fs-l-w-c(14, 16, 700, $c5);
        }

        .val {
          position: relative;
          width: pxTo(240);
          height: pxTo(60);
          border: 2px dashed $c5;
          border-radius: pxTo(4);
          margin: pxTo(6) auto 0 auto;
          text-align: center;

          @extend %ff-rb;
          @include fs-l-w-c(20, 58, 700, $c9);
        }

        .copySuccessMessage{
          position: absolute;
          font-size: 14px;
          transition: all .5s;
          opacity: 0;
          left: 50%;
          transform: translate(-50%, -130px);
        }

        .txt {
          text-align: center;
          margin-top: pxTo(6);

          @include fs-l-w-c(12, 14, 400, $c10);
        }
      }
    }
  }
</style>