<template>
  <div class="banner">
    <div class="coupon fx-row fx-center"
         v-if="info">
      <div class="left"
           :style="{backgroundImage:goodsThumb}"></div>
      <div class="right fx-1">
        <div class="title">{{title}}</div>
        <div class="desc">{{desc}}</div>
        <div class="price fx-row fx-v-center">
          Rp{{formatSplitMoney(info.coupon_price)}}
          <div class="origin"
               v-if="info.origin_price">Rp{{info.origin_price}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formatBackgroundImgUrl from '../../../filters/formatBackgroundImgUrl'
import formatSplitMoney from '../../../filters/formatSplitMoney'

export default {
  props: ['info', 'total'],
  computed: {
    activityId () {
      return this.info && this.info.activity_id || ''
    },
    goodsThumb () {
      let img = this.info && this.info.goods_thumbnail || ''
      return formatBackgroundImgUrl(img)
    },
    title () {
      return this.info && this.info.coupon_title || ''
    },
    desc () {
      return this.info && this.info.coupon_desc || ''
    }
  },
  methods: {
    formatSplitMoney,
  }
}
</script>

<style lang="scss" scoped>
.banner {
  height: pxTo(157);
  background: $c1;
  padding-top: pxTo(16);

  .coupon {
    width: pxTo(331);
    height: pxTo(125);
    margin: 0 auto;

    @include bg($img-base, "coupon-bg.png");
    background-size: 100% 100%;

    .left {
      width: pxTo(105);
      height: 100%;
      background-color: #f4f4f4;
      border-radius: pxTo(5) 0 0 pxTo(5);
      background-size: 100% auto !important;
      background-position: center center !important;
      background-repeat: no-repeat !important;

      &.free {
        @include bg($img-base, "free.png");
        background-size: pxTo(97) pxTo(30);
      }

      &.discount {
        @include bg($img-base, "discount.png");
        background-size: pxTo(73) pxTo(46);
      }
    }

    .right {
      padding-left: pxTo(12);
      padding-right: pxTo(12);

      .title {
        margin-bottom: pxTo(8);
        word-break: break-all;
        text-overflow: -o-ellipsis-lastline;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        @extend %ff-rm;
        @include fs-l-w-c(16, 19, 500, $c9);
      }

      .desc {
        word-break: break-all;
        text-overflow: -o-ellipsis-lastline;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        @extend %ff-rr;
        @include fs-l-w-c(12, 14, 400, $c10);
      }
      
      .price {
        @extend %ff-rr;
        @include fs-l-w-c(12, 14, 400, $c10);
      }

      .price {
        margin-top: pxTo(4);

        @extend %ff-rm;
        @include fs-l-w-c(20, 16, 500, $c16);

        .origin {
          text-decoration: line-through;
          margin-left: pxTo(10);

          @include fs-l-w-c(14, 10, 400, $c20);
        }
      }
    }
  }
}
</style>