<template>
  <div class="invite iphone-x">
    <nav-bar show-back="1" show-my="1">
      <round-v></round-v>
    </nav-bar>

    <banner :info="info"></banner>

    <groups-intro :num="groupNum"></groups-intro>
    <!--count-down :info="info" @over="fresh"></count-down-->
    <groups :info="info" @over="fresh"></groups>

    <!-- 推荐商品 -->
    <recommend-goods></recommend-goods>

    <bottom :info="info" @fresh="fresh"></bottom>

    <popup-free-coupon :is-show="isShowFreeCoupon" :id="id"
                       @fresh="fresh" @close="setShowFreeCoupon"></popup-free-coupon>
  </div>
</template>

<script>
  import formatDiscountRate from '../../filters/formatDiscountRate'
  //component
  import NavBar from '../../components/navBar'
  import RoundV from '../../components/roundV'
  import Banner from './components/banner'
  import GroupsIntro from '../../components/groupsIntro'
  //import CountDown from './components/countDown'
  import Groups from './components/groups'
  import RecommendGoods from '../../components/recommendGoods'
  import Bottom from './components/bottom'
  //弹窗
  import PopupFreeCoupon from '../../components/popupFreeCoupon'
  //api
  import service from '../../service'

  let id = ''

  export default {
    data() {
      return {
        info: false,
        isShowFreeCoupon: false
      }
    },
    components: {
      NavBar,
      RoundV,
      Banner,
      GroupsIntro,
      //CountDown,
      Groups,
      RecommendGoods,
      Bottom,
      PopupFreeCoupon
    },
    computed: {
      id() {
        return this.info && this.info.id || ''
      },
      groupNum() {
        return this.info && this.info.expected_members || 0
      },
      discountRate() {
        return formatDiscountRate(this.info && this.info.discount_rate || 0)
      },
      status() {
        return this.info && this.info.status || 0
      },
      hasJoin() {
        return this.info && this.info.has_join || 0
      },
      couponCode() {
        return this.info && this.info.coupon_code || ''
      },
      hasFreeCoupon() {
        return this.info && this.info.has_free_coupon == 1 || false
      }
    },
    watch: {
      '$route'() {
        if (this.info && this.$route.path.indexOf('groups/invite') >= 0) {
          this.init()
        }
      }
    },
    methods: {
      init() {
        id = this.$route.query.id || ''
        this.getGroupById()
      },
      fresh() {
        this.init()
      },
      getGroupById() {
        service.getGroupById(id).then((data) => {
          this.info = data

          //进行中且有免拼券
          if (this.status == 0 && this.hasFreeCoupon) {
            this.setShowFreeCoupon(true)
          }
        }).catch(this.$apiErrorHandler).then(this.$hideLoading)
      },
      setShowFreeCoupon(val) {
        this.isShowFreeCoupon = val
      }
    },
    created() {
      //添加登录成功监听
      this.$bus.$off('loginSuccess', this.init);
      this.$bus.$on('loginSuccess', this.init);

      this.$showLoading()
      this.init()

      window.track('wowsaa-groups', 'invite', 'expose')
    },
    beforeDestroy() {
      this.$bus.$off('loginSuccess', this.init);
    }
  }
</script>

<style lang="scss" scoped>
  .invite {
    padding-bottom: pxTo(86+40);

    @extend %page;
    background-color: $c11;
  }
</style>