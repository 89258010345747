<template>
  <div class="fx-center popup-mask" v-if="isShow" @click="onClose">
    <div class="free-coupon pupup-center fx-c-center" @click.stop="">
      <div class="btn-rainbow" @click="useFreeCoupon">
        <div>GUNAKAN KUPON</div>
      </div>
      <div class="desc">Dapat langsung digunakan sekarang!</div>
    </div>
  </div>
</template>

<script>
  import service from '../service'

  let lock = false

  export default {
    props: ['isShow', 'id'],
    methods: {
      //使用免拼券
      useFreeCoupon() {
        if (lock) {
          return
        }
        lock = true

        this.$showLoading()

        service.useFreeCoupon(this.id).then(() => {
          window.track('wowsaa-groups', 'free-coupon', 'click')
          this.$emit('fresh')
          this.onClose()
        }).catch(this.$apiErrorHandler).then(() => {
          lock = false
        })
      },
      onClose() {
        this.$emit('close', false)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .popup-mask {
    background-color: rgba(0, 0, 0, .8);
  }

  .free-coupon {
    width: 95%;
    height: pxTo(325);
    background-color: transparent !important;
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: pxTo(300);
      position: fixed;
      top: 0;
      left: 0;

      @include bg($img-base, 'img_ribon_bg.png');
      background-size: 100% auto;
      background-position: top center;
    }

    &::after {
      content: "";
      display: block;
      width: pxTo(326);
      height: pxTo(80);
      position: absolute;
      top: pxTo(-73);

      @extend %h-center;
      @include bg($img-base, 'img_free_coupon_title.png');
      background-position: top center;
      background-size: 100% auto;
    }

    @include bg($img-base, 'img_free_coupon.png');
    background-size: 100% auto;
    background-position: top center;

    .btn-rainbow {
      width: pxTo(252);
      height: pxTo(42);
      margin: pxTo(207) auto 0 auto;
    }

    .desc {
      text-align: center;
      height: pxTo(13);
      margin-top: pxTo(4);

      @extend %ff-rm;
      @include fs-l-w-c(11, 13, 500, $c5);
    }
  }
</style>