<template>
  <div class="bottom iphone-x">
    <!-- 拼团中 -->
    <div class="single" v-if="status == 0">
      <!-- 加入拼团 -->
      <div class="btn" v-if="hasJoin == 0 && status == 0"
           @click="onJoin">SAYA INGIN JOIN GRUP
      </div>
      <!-- 邀请好友拼团 -->
      <div class="btn" v-else-if="hasJoin == 1 && status == 0"
           @click="onShare">UNDANG TEMAN UNTUK DAPATKAN KUPON
      </div>
    </div>

    <!-- 拼团结束 -->
    <!-- 成功已加入 -->
    <!--div class="single" v-else-if="discountRate == 0 && status == 1 && hasJoin == 1">
      <创建一个新的组>
      <div class="btn" @click="onStartNewGroup">BUAT GRUP BARU</div>
    </div-->

    <!-- 成功已加入 -->
    <!-- 是免费商品(info.category === 3)的情况，不需要该按钮 -->
    <div class="single" v-else-if="status == 1 && hasJoin == 1 || info.category !== 3">
      <!-- 复制券码并购买 -->
      <div id="copyBtn" class="btn" @click="onCopyAndGotoShop">SALIN KODE KUPON DAN BELI</div>
    </div>

    <!-- 成功未加入 -->
    <div class="single" v-else-if="status == 1 && hasJoin !=1">
      <!-- 我想买张优惠 -->
      <div class="btn" @click="onStartNewGroup">SAYA INGIN MENDAPATKAN KUPON</div>
    </div>

    <!-- 失败 -->
    <div class="double fx-row" v-else-if="status > 1">
      <!-- 我的组 -->
      <div class="fx-1 fx-center" @click="onStartNewGroup">
        <div class="txt" @click="gotoMy">GRUP SAYA</div>
      </div>
      <!-- 免费的话费 -->
      <div class="fx-1 fx-center group">
        <div class="txt" @click="gotoHome">GRATIS BIAYA PANGGILAN</div>
      </div>
    </div>


    <!-- 复制代码并去店铺购买 -->
    <!--div id="copyBtn" class="btn" v-else-if="hasJoin == 1 && status == 1 && couponCode"
         @click="onCopyAndGotoShop">SALIN KODE & PERGI MEMBELI
    </div-->
    <!-- 发起一个新的拼团 -->
    <!--div class="btn" v-else-if="hasJoin == 1" @click="onStartNewGroup">BUAT GRUP BAR</div-->
    <!-- 我想得到一张优惠券(失败) -->
    <!--div class="btn" v-else @click="gotoHome">SAYA INGIN MENDAPATKAN KUPON</div-->
  </div>
</template>

<script>
  import Clipboard from '@/utils/clipboard'
  //mixin
  import groupHandler from '../../../mixins/groupHandler'

  let lock = false

  export default {
    props: ['info'],
    mixins: [groupHandler],
    computed: {
      id() {
        return this.info && this.info.id || ''
      },
      activityId() {
        return this.info && this.info.activity_id || ''
      },
      status() {
        return this.info && this.info.status || 0
      },
      hasJoin() {
        return this.info && this.info.has_join || 0
      },
      couponCode() {
        return this.info && this.info.coupon_code || ''
      },
      lessNum() {
        return this.totalNum - (this.info && this.info.current_members || 0)
      },
      totalNum() {
        return this.info && this.info.expected_members || 0
      }
    },
    methods: {
      onJoin() {
        if (lock) {
          return
        }
        lock = true

        //已登录
        this.joinById(this.id, this.activityId, true).then(() => {
          lock = false
        })
      },
      onShare() {
        let url = `${location.protocol}//${location.host}/groups/invite?id=${this.id}&from=invite`

        this.$bus.$emit('share', {
          url,
          info: {
            title: this.info && this.info.coupon_title || '',
            discount_rate: this.info && this.info.discount_rate || '',
            less: this.lessNum,
            total: this.totalNum,
            activityId: this.activityId
          }
        })
      },
      onCopyAndGotoShop() {
        if (this.couponCode && Clipboard && Clipboard.isSupported()) {
          let clipboard = new Clipboard('#copyBtn', {
            text: () => this.couponCode
          })

          clipboard.on("success", () => {
            this.gotoPage('shopify', {url: this.info && this.info.goods_url})
          })
        } else {
          this.gotoPage('shopify', {url: this.info && this.info.goods_url})
        }
      },
      onStartNewGroup() {
        if (lock) {
          return
        }
        lock = true

        this.startNewGroup(this.activityId).then(() => {
          lock = false
        })
      },
      gotoHome() {
        this.gotoPage('home')
      },
      gotoMy() {
        return this.$getAuth().then(() => {
          this.$showLoading()

          //已登录
          this.gotoPage('my')
        }).catch(() => {
          //未登录
          this.$bus.$emit('login', {
            callback: () => {
              this.gotoPage('my')
            }
          })
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .bottom {
    width: 100%;
    position: fixed;
    z-index: 9;
    bottom: 0;
    left: 0;
    background-color: $c2;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.10);

    .single {
      padding: pxTo(19) pxTo(15) pxTo(25) pxTo(15);

      .btn {
        height: pxTo(42);
        background: $bg1;
        background-size: 100% 100%;
        border-radius: pxTo(6);
        text-align: center;

        @extend %ff-rb;
        @include fs-l-w-c(14, 42, 900, $c2);
      }
    }

    .double {
      > div {
        height: pxTo(48);
        position: relative;

        .icon {
          width: pxTo(24);
          height: pxTo(24);
        }

        .txt {
          margin-left: pxTo(8);
          text-align: center;

          @extend %ff-rb;
          @include fs-l-w-c(13, 13, 800, $c5);
        }

        &.group {
          background-image: $bg1;

          .icon {
            @include bg($img-base, 'ic_flame.svg');
            background-size: auto 100%;
          }

          .txt {
            color: $c2;
          }
        }
      }
    }
  }
</style>